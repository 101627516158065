import { createSlice } from "@reduxjs/toolkit";

/**
 * Кусок хранилища Redux
 * @module src/store/slice/header
 */
export const headerSlice = createSlice({
  name: "header",
  initialState: {
    value: "light",
  },
  reducers: {
    setHeader: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { setHeader } = headerSlice.actions;
export const getHeader = (state) => state.header.value;
export default headerSlice.reducer;
