import React from "react";
import { Provider } from "react-redux";
import { ThemeProvider } from "@material-ui/core/styles";
import { CssBaseline } from "@material-ui/core";

import store from "./src/store";
// import ClientOnly from "./src/clientOnly.js";
import theme from "./src/templates/theme.js";

/**
 * Основная обертка страниц
 * @module mui-root-wrapper
 */
export default function MuiRootWrapper({ element }) {
  try {
    return (
      <Provider store={store}>
        {/* <ClientOnly> */}
          <ThemeProvider theme={theme}>
            <CssBaseline />
            {element}
          </ThemeProvider>
        {/* </ClientOnly> */}
      </Provider>
    );
  } catch (e) {
    return (
      <Provider store={store}>
        {/* <ClientOnly> */}
          <ThemeProvider theme={theme}>
            <CssBaseline />
            {element}
          </ThemeProvider>
        {/* </ClientOnly> */}
      </Provider>
    );
  }
}
