import { createSlice } from "@reduxjs/toolkit";
import { navigate } from "gatsby";
import { languages } from "../../templates/languages";

function getInitLang() {
  if (typeof window === "undefined") return null;

  const firstElemInPathname = window.location.pathname.split("/")[1];
  const saveLang = window?.localStorage.getItem("lang");
  const userLang = navigator?.language || navigator?.userLanguage;

  let lang =
    languages[firstElemInPathname]?.value?.slice(0, 2) ||
    languages[saveLang]?.value?.slice(0, 2) ||
    languages[userLang?.slice(0, 2)]?.value?.slice(0, 2) ||
    languages.en.value.slice(0, 2);

  window.localStorage.setItem("lang", lang);
  return lang;
}

/**
 * Кусок хранилища Redux
 * @module src/store/slice/lang
 */
export const langSlice = createSlice({
  name: "lang",
  initialState: {
    value: getInitLang(),
  },
  reducers: {
    setLang: (state, action) => {
      window.localStorage.setItem("lang", action.payload);
      state.value = action.payload;
    },
  },
});

export const { setLang } = langSlice.actions;
export const getLang = (state) => state.lang.value;
export default langSlice.reducer;

export const setNewLang = (lang) => (dispatch) => {
  dispatch(setLang(lang));

  const path = window.location.pathname;
  const newPath = [lang].concat(path.split("/").slice(2)).join("/");

  navigate(`/${newPath}`);
};
