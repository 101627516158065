import { configureStore } from "@reduxjs/toolkit";

import langReducer from "./slices/lang.js";
import headerReduser from "./slices/header.js";

/**
 * Хранилище Redux
 * @module src/store/index
 */
export default configureStore({
  reducer: {
    lang: langReducer,
    header: headerReduser,
  },
});
