import { createMuiTheme } from "@material-ui/core/styles";

const theme = createMuiTheme({
  palette: {
    color: {
      main: "#1E2C41",
      secondary: "#4E5A6C",
      mainContrast: "#FFFFFF",
      accent: "#1A21D3",
    },
    background: {
      main: "#EFF1F6",
      secondary: "#FFFFFF",
      accent: "#1A21D3",
    },
    width: {
      limit: "1440px",
    },
  },
  typography: {
    fontFamily: ["'Arimo'", "Raleway"].join(","),
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        body: {
          margin: 0,
          background: "#EFF1F6",
          "-webkit-background-clip": "border-box",
          backgroundClip: "border-box",
          "-webkit-text-fill-color": "none",
          backgroundColor: "#EFF1F6",
          overflowX: "hidden",

          "& *": {
            color: "#1E2C41",
            fontFamily: "'Arimo'",
          },
        },
        button: {
          padding: 0,
          border: "none",
          background: "transparent",
          cursor: "pointer",
        },
        a: {
          textDecoration: "none",
        },
      },
    },
    MuiButton: {
      root: {
        minWidth: 0,
        minHeight: 0,
        padding: 0,
        "&:hover": {
          backgroundColor: "none",
        },
      },
      text: {
        minWidth: 0,
        minHeight: 0,
        padding: 0,
        textTransform: "none",
      },
    },
  },
  props: {
    MuiButton: {
      disableRipple: true,
    },
  },
});

/**
 * Тема оформления (используется библиотекой material-ui)
 * @module src/templates/theme
 */
export default theme;
